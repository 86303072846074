<template>
    <v-app>
        <v-main>
            <slot></slot>
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: "LayoutBlank"
}
</script>

<style scoped>

</style>
