import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        fontFamily: 'Gilroy',
        themes: {
            light: {
                primary: '#01A1B7',
                gray: '#787F95',
                dark: '#002B48'
            }
        }
    }
});
