<template>
  <v-app>
    <v-main>
      <div class="register-page">
        <div class="sidebar">
          <img src="/image/logo-white.png" alt="" class="w-50 d-block mx-auto my-16" width="50%">
        </div>
        <slot></slot>
      </div>
    </v-main>

  </v-app>

</template>

<script>
export default {
  name: "LayoutRegister",

}
</script>

<style scoped>

</style>
